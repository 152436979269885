import { useMap } from "react-leaflet";
import { Button, Tooltip } from "@fluentui/react-components";
import { ArrowHookDownLeftRegular, ZoomFitRegular, ZoomInRegular } from '@fluentui/react-icons';

const MapControlButton = ({ tooltipContent, onClick, icon }) => (
  <Tooltip content={tooltipContent} relationship="label" positioning="below">
    <Button className="map-control-button" onClick={onClick} icon={icon} />
  </Tooltip>
);

const HomeButton = () => {
  const map = useMap();
  const handleHomeButtonClick = () => {
    map.flyTo([52.295, 7.0317], 8, {
      duration: 0.5,
      easeLinearity: 0.5,
    });
  };
  return (
    <MapControlButton
      tooltipContent="Zet kaartweergave terug naar oorspronkelijke staat"
      onClick={handleHomeButtonClick}
      icon={<ArrowHookDownLeftRegular />}
    />
  );
};

const FocusOnFilteredHousesButton = ({ focusMap }) => (
  <MapControlButton
    tooltipContent="Breng gefilterd vastgoed naar voren"
    onClick={focusMap}
    icon={<ZoomFitRegular />}
  />
);

const FilteredHousesToInViewButton = ({ setFilteredHousesInView, housesInView }) => {
  const map = useMap();
  const handleFilteredHousesToInView = () => {
    const bounds = housesInView.map(house => [house.lat, house.lng]);
    if (bounds.length > 0) {
      map.fitBounds(bounds, {
        padding: [50, 50],
        duration: 0.5,
        easeLinearity: 0.5,
      });
    }
    setFilteredHousesInView(housesInView);
  };
  return (
    <MapControlButton
      tooltipContent="Filter naar vastgoed in weergave"
      onClick={handleFilteredHousesToInView}
      icon={<ZoomInRegular />}
    />
  );
};

const MapControls = ({ focusMap, setFilteredHousesInView, housesInView }) => (
  <div id="map-controls">
    <HomeButton />
    <FocusOnFilteredHousesButton focusMap={focusMap} />
    <FilteredHousesToInViewButton
      setFilteredHousesInView={setFilteredHousesInView}
      housesInView={housesInView}
    />
  </div>
);

export default MapControls;
