import { Labels } from "../../constants/EPOnline";
import { RealEstateRegular, SlideSizeRegular, PositionForwardFilled } from '@fluentui/react-icons';
import { LightningBoltIcon } from '@fluentui/react-icons-mdl2';
import { makeStyles, tokens } from "@fluentui/react-components";

interface VasgoedItemProps {
  house: any;
  clickHandle: (vge: any) => void;
  activeId: number | undefined;
  setActiveId: (id: number) => void;
}

const useStyles = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
    fontSize: "24px",
  },
});

const VasgoedItem: React.FC<VasgoedItemProps> = ({
  house,
  clickHandle,
  activeId,
  setActiveId,
}) => {

  const styles = useStyles();
  const label = Labels.find((l) => l.name === house.e_l);
  return (
    <li key={house.id} aria-selected={house.id === activeId}>
      <div
        style={{ display: "grid", gridRowGap: "8px" }}
        onClick={() => {
          clickHandle(house);
          setActiveId(house.id);
        }}
      >
        <p style={{ display: "flex", alignItems: "center" }}>
          {house.s} {house.nr}
          <p style={{ fontSize: "10px", color: "#838383", marginRight: "3px" }}>
            {" "}
            - {house.w}
          </p>
        </p>
        <div style={{ display: "grid", gridRowGap: "8px" }} className="symbols">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
              <RealEstateRegular className={styles.iconColor}/>
                <p className="long-text" title={house.dSrt}>
                  {house.dSrt}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <div>
              <SlideSizeRegular className={styles.iconColor}/>
                <p title={house.oppervlakte} className="long-text">
                  {house.oppervlakte}m²
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <div>
              <PositionForwardFilled className={styles.iconColor}/>
                <p title={house.oppervlakte} className="long-text">
                  4
                </p>
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginTop: "2px" }}>
              <LightningBoltIcon style={{ color: label?.color }}/>
            </div>
            <p style={{ marginLeft: "1px" }}>{house.e_l}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default VasgoedItem;
