import { SearchBox } from "@fluentui/react-components";

const SearchBoxComponent = ({ searchQuery, handleSearchChange, styles }) => {
  return (
    <SearchBox
      value={searchQuery}
      onChange={(_, data) => handleSearchChange(undefined, data.value)}
      placeholder="Zoeken"
      className={styles.searchBox}
    />
  );
};

export default SearchBoxComponent;
