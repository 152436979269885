import { makeStyles, tokens } from "@fluentui/react-components";
import {
  ContactCardRegular,
  WrenchRegular,
  PeopleToolboxRegular,
  PeopleTeamToolboxRegular,
} from "@fluentui/react-icons";

const useStyles = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
    fontSize: "24px",
  },
});

const KlantItem = ({ house, clickHandle, activeId, setActiveId }) => {
  const styles = useStyles();
  return (
    <li key={house.id} aria-selected={house.id === activeId}>
      <div
        onClick={() => {
          clickHandle(house);
          setActiveId(house.id);
        }}
      >
        <div className="house-info">
          <p>
            <span className="house-type">{house.Type}</span>
          </p>
        </div>
        <div className="symbols">
          <div>
            <ContactCardRegular className={styles.iconColor} />
            <p>{house.Oorsprong}</p>
          </div>

          <div>
            <WrenchRegular className={styles.iconColor} />
            <p>{house.SoortReparatieverzoek}</p>
          </div>

          <div>
            <PeopleToolboxRegular className={styles.iconColor} />
            <p>{house.Uitvoerder}</p>
          </div>

          <div>
            <PeopleTeamToolboxRegular className={styles.iconColor} />
            <p>{house.TeamUitvoerder}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default KlantItem;
