import { Labels } from "../../constants/EPOnline";
import { GooglePhoto } from "../GoogleMaps/GooglePhoto";
import { RealEstateRegular, SlideSizeRegular, PositionForwardFilled } from '@fluentui/react-icons';
import { LightningBoltIcon } from '@fluentui/react-icons-mdl2';
import { makeStyles, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
    fontSize: "24px",
  },
});

const VasgoedLocationDetails = ({ vge, initializeStreetView }) => {
  const styles = useStyles();
  const vgtEditUrl =
    "https://vastgoedtabel-ontw-ui.azurewebsites.net/vge/edit/" + vge.id;
  const label = Labels.find((l) => l.name === vge.e_l);
  return (
    <div className="location-details-container">
      <div className="location-details">
        <div className="location-image">
          <div style={{ cursor: "pointer" }} onClick={initializeStreetView}>
            <GooglePhoto houseData={vge} />
          </div>
        </div>
        <div className="location-content">
          <a id="first-child" className="link" href={vgtEditUrl} target="blank">
            <p>
              {vge.s} {vge.nr}
            </p>
          </a>
          <p>{vge.w}</p>
          <p>{vge.b}</p>
          <div className="symbols" id="last-child">
            <div>
            <RealEstateRegular className={styles.iconColor}/>
              <p title={vge.dSrt} className="long-text">
                {vge.dSrt}
              </p>
              <div style={{ marginLeft: "15px" }}>
              <SlideSizeRegular className={styles.iconColor}/>
                <p title={vge.oppervlakte} className="long-text">
                  {vge.oppervlakte}m²
                </p>
              </div>
              <div style={{ marginLeft: "15px" }}>
              <PositionForwardFilled className={styles.iconColor}/>
                <p title={vge.oppervlakte} className="long-text">
                  4
                </p>
              </div>
            </div>
            <div>
              <div style={{ marginTop: "2px", marginRight: "3px" }}>
              <LightningBoltIcon style={{ color: label?.color }}/>
              </div>
              <p style={{ marginLeft: "1px" }}>{vge.e_l}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VasgoedLocationDetails;
