import React, { useState } from "react";
import { ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import { Button, Checkbox } from "@fluentui/react-components";

const itemsToShowInitially = 8;

interface FilterCheckboxProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  houseCount: number;
}

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({
  label,
  checked,
  onChange,
  houseCount,
}) => (
  <div className="filter-label">
    <Checkbox label={label} checked={checked} onChange={onChange} />
    <span className="house-count">{houseCount}</span>
  </div>
);

interface CollapsibleFilterSectionProps {
  title?: string;
  options: string[];
  selectedItems: string[];
  handleCheckboxChange: (option: string) => void;
  getHouseCount: (option: string, label: string) => number;
  label: string;
}

const CollapsibleFilterSection: React.FC<CollapsibleFilterSectionProps> = ({
  title,
  options = [],
  selectedItems = [],
  handleCheckboxChange,
  getHouseCount,
  label,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleShowAll = () => setShowAll(!showAll);

  return (
    <div className="filter-parent">
      <div className="dropdown-toggle arrow-toggle" onClick={toggleDropdown}>
        <h3>{title}</h3>
        {dropdownVisible ? <ChevronUp24Regular /> : <ChevronDown24Regular />}
      </div>
      {dropdownVisible && (
        <div>
          <div className={`checkbox-container ${showAll ? "show-all" : ""}`}>
            {options
              .slice(0, showAll ? options.length : itemsToShowInitially)
              .map((option) => (
              <FilterCheckbox
                key={option}
                label={option}
                checked={selectedItems.includes(option)}
                onChange={() => handleCheckboxChange(option)}
                houseCount={getHouseCount(option, label)}
              />
            ))}
          </div>
          {options.length > itemsToShowInitially && (
            <div className="toon">
              <Button shape="circular" onClick={toggleShowAll}>
                {showAll
                  ? `Toon minder`
                  : `Toon meer (${options.length - itemsToShowInitially})`}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapsibleFilterSection;
