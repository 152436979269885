import Filter from "./CollapsibleFilter";

function ContractFilter(props) {
  const {
    selectedEenheidDetailsoort,
    handleEenheidDetailsoortCheckboxChange,
    getHouseCountForFilter,
    selectedWoonplaats,
    handleWoonplaatsCheckboxChange,
    selectedCBSBuurt,
    handleCBSBuurtCheckboxChange,
  } = props;

  return (
    <div className="side-left">
      <h2 className="heading">Verhuureenheid</h2>
      <Filter
        title="Status"
        options={[
          { id: 1, name: "Leegstand" },
          { id: 2, name: "Verhuurd" },
          { id: 3, name: "Inactief" },
          { id: 4, name: "Verkocht" },
        ].map((option) => option.name)}
        selectedItems={selectedEenheidDetailsoort}
        handleCheckboxChange={handleEenheidDetailsoortCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="srt"
      />

      <Filter
        title="Bestemming"
        options={[
          { id: 1, name: "Verhuur" },
          { id: 2, name: "Sloop" },
          { id: 3, name: "Verkoop" },
        ].map((option) => option.name)}
        selectedItems={selectedWoonplaats}
        handleCheckboxChange={handleWoonplaatsCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="w"
      />

      <Filter
        title="Gepubliceerde"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Maatschappelijk label"
        options={[
          { id: 1, name: "DAEB" },
          { id: 2, name: "NietDAEB" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Hoogte huurprijs"
        options={[
          { id: 1, name: "< 442.46" },
          { id: 2, name: "442.46 - 633,25" },
          { id: 3, name: "633,25 - 763,47" },
          { id: 3, name: "> 763,47" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="WWS Profiel"
        options={[
          { id: 1, name: "Zelfstandig" },
          { id: 2, name: "Onzelfstandig" },
          { id: 3, name: "Woonwagen" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Onzelfstandige woonruimte"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="2 Huren beleid"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <h2 className="heading">Huurcontract</h2>
      <Filter
        title="Huurcontract beëindigd"
        options={[
          { id: 1, name: "Niet passende grootte" },
          { id: 2, name: "Niet passende prijs" },
          { id: 3, name: "Buurt" },
          { id: 4, name: "Probleem" },
          { id: 5, name: "Overleden" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />

      <Filter
        title="Tijdelijke huurcontract"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Serviceabonnement"
        options={[
          { id: 1, name: "Glasfonds" },
          { id: 2, name: "Rioolfonds" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Vervangende betaler"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Incassowijze"
        options={[
          { id: 1, name: "Zelf Betalen" },
          { id: 2, name: "Auto Incasso " },
          { id: 3, name: "Betaallink" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <h2 className="heading">Huishouden</h2>
      <Filter
        title="Grootte"
        options={[
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "4" },
          { id: 4, name: "5" },
          { id: 4, name: "6+" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Leeftijd"
        options={[
          { id: 1, name: "18-23" },
          { id: 2, name: "24-28" },
          { id: 3, name: "29-55" },
          { id: 4, name: "56-65" },
          { id: 5, name: "66+" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <h2 className="heading">Incasso</h2>
      <Filter
        title="Betalingsachterstand"
        options={[
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "4+" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Betalingsregeling"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
      <Filter
        title="Deurwaarder"
        options={[
          { id: 1, name: "Ja" },
          { id: 2, name: "Nee" },
        ].map((option) => option.name)}
        selectedItems={selectedCBSBuurt}
        handleCheckboxChange={handleCBSBuurtCheckboxChange}
        getHouseCount={getHouseCountForFilter}
        label="b"
      />
    </div>
  );
}

export default ContractFilter;
