import KlantFilters from "./KlantFilters";
import MarketFilter from "./MarketFilter";
import ContractFilter from "./ContractFilter";
import FilterComponent from "./VasgoedFilter";
import { Label } from "constants/EPOnline";
import { Checkbox } from "@fluentui/react-components";
import { useStyles } from "styles/index.styles";
import filterConfig from "config/FilterConfig";

const DynamicFiltersComponent = ({ state, setState, Labels }) => {
  const styles = useStyles();
  const updateState = (key, value) =>
    setState((prevState) => ({ ...prevState, [key]: value }));

  const itemsToShowInitially = 8;

  const handleCheckboxChange = (type: string) => (item: string) => {
    setState((prevState) => ({
      ...prevState,
      [type]: prevState[type].includes(item)
        ? prevState[type].filter((prevItem) => prevItem !== item)
        : [...prevState[type], item],
    }));
  };

  const getHouseCountForFilter = (filterOption: string, ...args: string[]) => {
    var amount = 0;

    for (const house of state.filteredHouses) {
      let value = house;
      for (const arg of args) {
        value = value[arg];
      }
      if (value?.toString() === filterOption) {
        amount++;
      }
    }
    return amount;
  };

  const getFilterConfig = (filterKey) =>
    filterConfig.find((config) => config.filterKey === filterKey);

  const eponlineLabel = (labelletter: Label) => {
    const labelConfig = getFilterConfig("e_l");
    if (!labelConfig) return null;
    let labelDiv = (
      <div
        key={labelletter.name}
        className={styles.filterLabel}
        style={{
          background: `linear-gradient(31deg, ${labelletter.color} 10%, white ${labelletter.gradientLength}, white) right center / 170px 100% no-repeat`,
          height: "25px",
          margin: "5px",
        }}
      >
        <Checkbox
          label={<span style={{ fontWeight: "bold" }}>{labelletter.name}</span>}
          checked={state[labelConfig.stateKey].includes(labelletter.name)}
          onChange={() =>
            handleCheckboxChange(labelConfig.stateKey)(labelletter.name)
          }
        />
        <span className={styles.houseCount}>
          {getHouseCountForFilter(labelletter.name, labelConfig.filterKey)}
        </span>
      </div>
    );
    return <div>{labelDiv}</div>;
  };

  const epOnlineLabels = (labels: Label[]) => {
    return (
      <div className={styles.epLabels}>
        {labels.map((label) => {
          return eponlineLabel(label);
        })}
      </div>
    );
  };

  const sharedProps = {
    itemsToShowInitially,
    getHouseCountForFilter,
    ...state,
    ...Object.keys(filterConfig).reduce((acc, key) => {
      if (filterConfig[key].handler) {
        acc[filterConfig[key].handler] = handleCheckboxChange(
          filterConfig[key].stateKey
        );
      }
      return acc;
    }, {}),
    setSliderLabel: (value) => updateState("sliderLabel", value),
    setMonthsRange: (value) => updateState("monthsRange", value),
    epOnlineLabels,
    Labels,
  };

  if (state.showKlantContent) {
    return <KlantFilters {...sharedProps} />;
  } else if (state.showMarketContent) {
    return <MarketFilter {...sharedProps} />;
  } else if (state.showContractContent) {
    return <ContractFilter {...sharedProps} />;
  } else {
    return <FilterComponent {...sharedProps} />;
  }
};

export default DynamicFiltersComponent;
